<template>
  <el-dialog
    :title="'Change Slack Id'"
    :visible.sync="setShow"
    append-to-body
    @close="close"
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row v-if="role === 'gardener'">
        <el-col :span="24">
          <el-form-item label="Email">
            <el-input v-model="form.email" type="text" disabled />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="" prop="slack_id" :rules="validateField()">
            <el-input
              v-model="form.slack_id"
              type="text"
              placeholder="Enter slack id"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="setShow = false">Cancel</el-button>
      <el-button
        type="primary"
        :loading="changing"
        :disabled="!form.slack_id"
        @click="changeSlackId"
        >Change</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import gardener from "@/requests/gardeners/gardener";
import member from "@/requests/settings/members/member";

export default {
  name: "MemberSlackIdChange",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    id: {
      type: [Number, String],
      default: "",
    },
    slackId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        slack_id: "",
      },
      changing: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.slack_id = this.slackId;
        if (this.role === "gardener" || this.role === "gardener_pro") {
          this.form.email = this.email;
        }
      }
    },
  },
  methods: {
    close() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    changeSlackId() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.changing = true;
        this.form.slack_id = this.form.slack_id.toUpperCase();
        this.role === "gardener" || this.role === "gardener_pro"
          ? this.gardenerChange()
          : this.userChange();
        return true;
      });
    },
    gardenerChange() {
      gardener
        .update(this.id, this.form)
        .then((response) => {
          const { status, message } = response.data;

          if (status) {
            this.$message.success(message);
            this.$emit("success", this.form.slack_id);
            this.close();
          } else {
            this.$message.error(response.data.message);
          }
          this.changing = false;
        })
        .catch((error) => {
          this.changing = false;
          this.$message.error(error.response.data.message);
        });
    },
    userChange() {
      member
        .slack(this.id, this.form)
        .then((response) => {
          const { status, message } = response.data;

          if (status) {
            this.$message.success(message);
            this.$emit("success", this.form.slack_id);
            this.close();
          } else {
            this.$message.error(message);
          }
          this.changing = false;
        })
        .catch((error) => {
          this.changing = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss"></style>
